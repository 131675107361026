import React from 'react';

const GalleryCard = ({ imageURL, captionText }) => {
  return (
    <div className="1200px:max-w-4xl lg:max-w-2xl md:max-w-xl max-w-md mx-auto bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 md:h-[34rem] h-[18rem]">
      <img className="w-full object-cover rounded-t-lg md:h-[30rem] h-[15rem]" src={imageURL} alt="" />
      <div className='md:p-5 p-[14px] bg-gray-200 rounded-b-lg'>
        <p className='text-center text-sm md:text-base text-blue-500 font-semibold uppercase'>{captionText}</p>
      </div>
    </div>
  )
}

export default GalleryCard