import React from 'react'
import Banner from './Banner.js';
import { NavLink } from 'react-router-dom';

const Contact = () => {
  return (
    <div className='w-full py-2'>
      <Banner title="Contact Us" />

      <div className='flex flex-col 1200px:flex-row mx-auto py-4 justify-center items-center'>

        <div className="w-full max-w-sm p-4 mb-4 mx-auto bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
          <form className="space-y-6" action="#">
            <h5 className="mb-4 1200px:text-2xl text-lg text-center font-semibold tracking-tight text-gray-900 dark:text-white">Leave a message</h5>
            <div>
              <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name</label>
              <input type="name" name="name" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required />
            </div>
            <div>
              <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone</label>
              <input type="text" name="phone" id="phone" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required />
            </div>
            <div>
              <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
              <input type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required />
            </div>
            <div>
              <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Message</label>
              <textarea id="message" rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"></textarea>
            </div>

            <button type="submit" className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>

          </form>
        </div>

        <div className='border border-gray-200 mx-auto rounded-lg 1200px:my-0 my-8'>
          <iframe className='1200px:w-[600px] w-96 h-96 1200px:h-[600px] border border-gray-200 rounded-lg' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.21632741511!2d77.64994680000001!3d13.0218917!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae174fe6d1a979%3A0x74de7b7a3fd148b2!2sARRKS%20INOVTECH!5e0!3m2!1sen!2sin!4v1711718373801!5m2!1sen!2sin" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>

      </div>

      <div className='grid grid-cols-1 1200px:grid-cols-3 gap-4 justify-center py-8 mx-auto 1200px:mx-12'>
        <div className="1200px:max-w-sm w-96 h-68 p-6 my-4 mx-auto text-center bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
          <svg className="1200px:w-10 1200px:h-10 w-8 h-8 mx-auto text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
            <path d="M2.038 5.61A2.01 2.01 0 0 0 2 6v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6c0-.12-.01-.238-.03-.352l-.866.65-7.89 6.032a2 2 0 0 1-2.429 0L2.884 6.288l-.846-.677Z" />
            <path d="M20.677 4.117A1.996 1.996 0 0 0 20 4H4c-.225 0-.44.037-.642.105l.758.607L12 10.742 19.9 4.7l.777-.583Z" />
          </svg>
          <h5 className="my-4 1200px:text-2xl text-lg font-semibold tracking-tight text-gray-900 dark:text-white">Email</h5>
          <p className="mb-3 font-normal text-sm 1200px:text-base text-gray-600 dark:text-gray-400">Email us for general queries, including marketing and partnership opportunities.</p>
          <p className="mb-3 font-semibold text-sm 1200px:text-base underline text-gray-900 dark:text-gray-400">contact@arrksinovtech.com</p>
          <p className="font-semibold text-sm 1200px:text-base underline text-gray-900 dark:text-gray-400">arrksinovtech@gmail.com</p>
        </div>

        <div className="1200px:max-w-sm w-96 h-68 my-4 p-6 mx-auto text-center bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
          <svg className="1200px:w-10 1200px:h-10 w-8 h-8 mx-auto text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
            <path d="M7.978 4a2.553 2.553 0 0 0-1.926.877C4.233 6.7 3.699 8.751 4.153 10.814c.44 1.995 1.778 3.893 3.456 5.572 1.68 1.679 3.577 3.018 5.57 3.459 2.062.456 4.115-.073 5.94-1.885a2.556 2.556 0 0 0 .001-3.861l-1.21-1.21a2.689 2.689 0 0 0-3.802 0l-.617.618a.806.806 0 0 1-1.14 0l-1.854-1.855a.807.807 0 0 1 0-1.14l.618-.62a2.692 2.692 0 0 0 0-3.803l-1.21-1.211A2.555 2.555 0 0 0 7.978 4Z" />
          </svg>
          <h5 className="my-4 1200px:text-2xl text-lg font-semibold tracking-tight text-gray-900 dark:text-white">Call</h5>
          <p className="mb-3 font-normal text-sm 1200px:text-base text-gray-600 dark:text-gray-400">Call us to speak to a member of our team. We are always happy to help.</p>
          <p className="font-semibold text-sm 1200px:text-base text-gray-900 dark:text-gray-400">+91 - 9606721325 <br /> +91 - 9606704225</p>
        </div>

        <div className="1200px:max-w-sm w-96 h-68 my-4 p-6 mx-auto text-center bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
          <svg className="1200px:w-10 1200px:h-10 w-8 h-8 mx-auto text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
            <path fillRule="evenodd" d="M11.906 1.994a8.002 8.002 0 0 1 8.09 8.421 7.996 7.996 0 0 1-1.297 3.957.996.996 0 0 1-.133.204l-.108.129c-.178.243-.37.477-.573.699l-5.112 6.224a1 1 0 0 1-1.545 0L5.982 15.26l-.002-.002a18.146 18.146 0 0 1-.309-.38l-.133-.163a.999.999 0 0 1-.13-.202 7.995 7.995 0 0 1 6.498-12.518ZM15 9.997a3 3 0 1 1-5.999 0 3 3 0 0 1 5.999 0Z" clipRule="evenodd" />
          </svg>

          <h5 className="my-4 1200px:text-2xl text-lg font-semibold tracking-tight text-gray-900 dark:text-white">Address</h5>
          <p className="mb-3 font-normal text-sm 1200px:text-base text-gray-600 dark:text-gray-400">Reach us. We are always happy to help.</p>
          <p className="font-semibold text-sm 1200px:text-base text-gray-900 dark:text-gray-400">#5AC, 959, 1st block, Hennur Road <br /> Horamavu, Babusapalya, ORR, Bangalore <br /> Karnataka, INDIA - 560043</p>
        </div>

      </div>

    </div>
  )
}

export default Contact;