import React from 'react';
import Banner from '../components/Banner.js';
import image1 from '../assets/about_1.jpg';
import CardsContainer from './CardsContainer.js';
import { teamData } from '../constants.js';

const About = () => {
  return (
    <div className='w-full py-2'>
      <Banner title="About Us" />

      <div className="container mx-auto p-4 text-center">
        <div className="flex flex-col 1200px:flex-row justify-between items-center gap-8 pb-4">
          <div className="w-full 1200px:w-1/2">
            <img className="w-full object-cover 1200px:h-[420px] md:h-72 sm:h-60 my-4 mx-auto" src={image1} alt="img" />
          </div>

          <div className="w-full 1200px:w-1/2 text-left">
            <p className="1200px:text-lg text-sm mb-4">
              At <span className='font-bold'>ARRKS Inovtech Pvt Ltd</span>, we are passionate about driving innovation and enabling
              businesses to thrive in the ever-evolving landscape of Industry 5.0. With a dedicated team of
              experts and a commitment to excellence, we offer a wide range of services tailored to the
              specific needs of manufacturing and process industries.
            </p>

            <p className="1200px:text-lg text-sm mb-4">
              Our journey began in Bangalore, the Silicon Valley of India, and since then, we have been at
              the forefront of driving digital transformation across various sectors. From Mechanical and
              Agriculture to Healthcare and beyond, we leverage our expertise to address the most pressing
              challenges and unlock new opportunities for growth.
            </p>

            <p className="1200px:text-lg text-sm">
              Driven by a culture of continuous learning and innovation, we also offer comprehensive
              training programs to equip individuals and organizations with the skills needed to succeed in
              the digital age. Join us in shaping the future of industry and technology!
            </p>
          </div>

        </div>
      </div>

      <Banner title="Our Team" />

      <CardsContainer data={teamData} delay={3000} type="team" />
    </div>
  )
}

export default About