import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../assets/logo.png";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      {windowWidth >= 1200 ? (
        <nav className="bg-white border-gray-200 dark:bg-gray-900 shadow-md fixed top-0 left-0 z-50 w-full">
          <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
            <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
              <img src={logo} className="h-10" alt="Logo" />
            </a>

            <div className="hidden w-full md:block md:w-auto">
              <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                <li>
                  <NavLink to="/" style={({ isActive }) => ({ color: isActive ? "white" : "black", backgroundColor: isActive ? "blue" : "white" })} className="block py-2 px-3 dark:text-white md:dark:text-white">
                    Home
                  </NavLink>
                </li>
                <li className="mb-2 md:mb-0">
                  <NavLink to="/about" style={({ isActive }) => ({ color: isActive ? "white" : "black", backgroundColor: isActive ? "blue" : "white" })} className="block py-2 px-3 dark:text-white md:dark:text-white">
                    About
                  </NavLink>
                </li>
                <li className="mb-2 md:mb-0">
                  <NavLink to="/services" style={({ isActive }) => ({ color: isActive ? "white" : "black", backgroundColor: isActive ? "blue" : "white" })} className="block py-2 px-3 dark:text-white md:dark:text-white">
                    Services
                  </NavLink>
                </li>
                <li className="mb-2 md:mb-0">
                  <NavLink to="/courses" style={({ isActive }) => ({ color: isActive ? "white" : "black", backgroundColor: isActive ? "blue" : "white" })} className="block py-2 px-3 dark:text-white md:dark:text-white">
                    Courses
                  </NavLink>
                </li>
                <li className="mb-2 md:mb-0">
                  <NavLink to="/products" style={({ isActive }) => ({ color: isActive ? "white" : "black", backgroundColor: isActive ? "blue" : "white" })} className="block py-2 px-3 dark:text-white md:dark:text-white">
                    Products
                  </NavLink>
                </li>
                <li className="mb-2 md:mb-0">
                  <NavLink to="/news" style={({ isActive }) => ({ color: isActive ? "white" : "black", backgroundColor: isActive ? "blue" : "white" })} className="block py-2 px-3 dark:text-white md:dark:text-white">
                    News and Events
                  </NavLink>
                </li>
                <li className="mb-2 md:mb-0">
                  <NavLink to="/contact" style={({ isActive }) => ({ color: isActive ? "white" : "black", backgroundColor: isActive ? "blue" : "white" })} className="block py-2 px-3 dark:text-white md:dark:text-white">
                    Contact
                  </NavLink>
                </li>
              </ul>
            </div>

          </div>
        </nav>
      ) : (
        <nav className="bg-white border-gray-200 dark:bg-gray-900 fixed top-0 left-0 z-50 w-full">
          <div className="max-w-screen-2xl flex flex-wrap items-center justify-between mx-auto p-4">

            <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
              <img src={logo} className="h-10" alt="Logo" />
            </a>

            <button onClick={() => setIsOpen(!isOpen)} type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg 1200px:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
              <span className="sr-only">Open main menu</span>
              <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
              </svg>
            </button>

            <div className={`1200px:flex w-full ${isOpen ? 'block' : 'hidden'} 1200px:block`}>
              <ul className={`font-medium flex flex-col 1200px:flex-row p-4 1200px:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 1200px:space-x-8 rtl:space-x-reverse 1200px:mt-0 1200px:border-0 1200px:bg-white dark:bg-gray-800 1200px:dark:bg-gray-900 dark:border-gray-700`}>
                <li>
                  <NavLink to="/" onClick={() => setIsOpen(false)} style={({ isActive }) => ({ color: isActive ? "white" : "black", backgroundColor: isActive ? "blue" : "rgb(249 250 251)" })} className="block py-2 px-3 rounded 1200px:border-0 1200px:p-0">
                    Home
                  </NavLink>
                </li>
                <li className="mb-2 1200px:mb-0">
                  <NavLink to="/about" onClick={() => setIsOpen(false)} style={({ isActive }) => ({ color: isActive ? "white" : "black", backgroundColor: isActive ? "blue" : "rgb(249 250 251)" })} className="block py-2 px-3 rounded 1200px:border-0 1200px:p-0">
                    About
                  </NavLink>
                </li>
                <li className="mb-2 1200px:mb-0">
                  <NavLink to="/services" onClick={() => setIsOpen(false)} style={({ isActive }) => ({ color: isActive ? "white" : "black", backgroundColor: isActive ? "blue" : "rgb(249 250 251)" })} className="block py-2 px-3 rounded 1200px:border-0 1200px:p-0">
                    Services
                  </NavLink>
                </li>
                <li className="mb-2 1200px:mb-0">
                  <NavLink to="/courses" onClick={() => setIsOpen(false)} style={({ isActive }) => ({ color: isActive ? "white" : "black", backgroundColor: isActive ? "blue" : "rgb(249 250 251)" })} className="block py-2 px-3 rounded 1200px:border-0 1200px:p-0">
                    Courses
                  </NavLink>
                </li>
                <li className="mb-2 1200px:mb-0">
                  <NavLink to="/products" onClick={() => setIsOpen(false)} style={({ isActive }) => ({ color: isActive ? "white" : "black", backgroundColor: isActive ? "blue" : "rgb(249 250 251)" })} className="block py-2 px-3 rounded 1200px:border-0 1200px:p-0">
                    Products
                  </NavLink>
                </li>
                <li className="mb-2 1200px:mb-0">
                  <NavLink to="/news" onClick={() => setIsOpen(false)} style={({ isActive }) => ({ color: isActive ? "white" : "black", backgroundColor: isActive ? "blue" : "rgb(249 250 251)" })} className="block py-2 px-3 rounded 1200px:border-0 1200px:p-0">
                    News and Events
                  </NavLink>
                </li>
                <li className="mb-2 1200px:mb-0">
                  <NavLink to="/contact" onClick={() => setIsOpen(false)} style={({ isActive }) => ({ color: isActive ? "white" : "black", backgroundColor: isActive ? "blue" : "rgb(249 250 251)" })} className="block py-2 px-3 rounded 1200px:border-0 1200px:p-0">
                    Contact
                  </NavLink>
                </li>
              </ul>
            </div>

          </div>
        </nav>
      )}
    </>
  );
};

export default Header;
