import React from 'react';
import { imageGalleryData, newsData } from '../constants.js';
import NewsCard from './NewsCard.js';
import Banner from './Banner.js'
import GalleryCard from './GalleryCard.js';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation, EffectCube } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-cube';

const News = () => {
  return (
    <div className='w-full py-2'>
      <Banner title="News and Events" />

      <div className='flex flex-wrap justify-center gap-8 py-4 mb-4 md:flex-row sm:flex-col px-4'>
        {newsData.map((card) => (
          <div key={card.id}>
            <NewsCard {...card} />
          </div>
        ))}
      </div>

      <Banner title="Image Gallery" />

      <div className='flex flex-col 1200px:flex-row justify-between items-center gap-8 pb-4 mb-4'>
        <div className='w-full'>
          <Swiper
            spaceBetween={30}
            centeredSlides={false}
            autoplay={{
              delay: 2500,
              disableOnInteraction: true,
              pauseOnMouseEnter: true
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation, EffectCube]}
            initialSlide={0}
            className="mySwiper mx-auto 1200px:max-w-[1200px] px-10 1200px:px-0 pt-4"
          >
            {
              imageGalleryData.map((card) => (
                <SwiperSlide className='h-auto' key={card.id}>
                  <GalleryCard imageURL={card.imageURL} captionText={card.captionText} />
                </SwiperSlide>
              ))
            }
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default News