import React from 'react'

const TeamCard = ({ id, name, imageURL, designation, qualification, experience, industry, academia, expertise }) => {
  return (
    <div className="max-w-sm bg-white cursor-pointer border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 h-[42rem] sm:h-auto">
      <img className="1200px:w-60 1200px:h-60 w-60 h-60 border-4 border-blue-500 my-4 mx-auto rounded-full shadow-lg" src={imageURL} alt="" />
      <div className="p-5 h-[27rem] text-center">
        <h5 className="mb-2 1200px:text-xl text-lg font-bold tracking-tight text-gray-900 dark:text-white">{name}</h5>
        <p className="mb-2 font-semibold 1200px:text-base text-sm text-gray-700 dark:text-gray-400">{designation}</p>
        {
          qualification !== '' && (
            <p className="mb-2 text-left font-normal 1200px:text-sm text-xs text-gray-700 dark:text-gray-400">
              <span className='font-semibold 1200px:text-base text-sm'>Qualification: </span> {qualification}
            </p>
          )
        }
        {
          experience !== '' && (
            <p className="mb-2 text-left font-normal 1200px:text-sm text-xs text-gray-700 dark:text-gray-400">
              <span className='font-semibold 1200px:text-base text-sm'>Experience: </span> {experience}
            </p>
          )
        }
        {
          industry !== '' && (
            <p className="mb-2 text-left font-normal 1200px:text-sm text-xs text-gray-700 dark:text-gray-400">
              <span className='font-semibold 1200px:text-base text-sm'>Industry: </span> {industry}
            </p>
          )
        }
        {
          academia !== '' && (
            <p className="mb-2 text-left font-normal 1200px:text-sm text-xs text-gray-700 dark:text-gray-400">
              <span className='font-semibold 1200px:text-base text-sm'>Academia: </span> {academia}
            </p>
          )
        }
        {
          expertise !== '' && (
            <p className="mb-2 text-left font-normal 1200px:text-sm text-xs text-gray-700 dark:text-gray-400">
              <span className='font-semibold 1200px:text-base text-sm'>Expertise: </span> {expertise}
            </p>
          )
        }
      </div>
    </div>
  )
}

export default TeamCard