import React from 'react';
import { useParams } from 'react-router-dom';
import { servicesData } from '../constants.js';

const ServiceDetails = () => {
    const { id } = useParams();
    const selectedId = servicesData.find((item) => item.id === Number(id));

    if (!selectedId) return;

    return (
        <div className='py-10'>
            <p>
                {selectedId.description}
            </p>
        </div>
    )
}

export default ServiceDetails