import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation, EffectCube } from 'swiper/modules';
import { descriptionData } from "../constants.js";

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-cube';

const Description = () => {
    return (

        <div className="container mx-auto p-4 text-center">
            <h1 className="1200px:text-3xl text-lg font-bold py-4 mb-4 text-blue-500">
                ARRKS INOVTECH
            </h1>
            <div className="flex flex-col 1200px:flex-row justify-between items-center gap-8 pb-4">
                <div className="w-full 1200px:w-1/2">
                    {/*<img className="w-full 1200px:h-[410px] h-72" src={image1} alt="img" />*/}
                    <Swiper
                        effect={'cube'}
                        cubeEffect={{
                            slideShadows: false,
                            shadow: false,
                            shadowOffset: 20,
                            shadowScale: 0.94,
                        }}
                        centeredSlides={false}
                        spaceBetween={30}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: true,
                            pauseOnMouseEnter: true
                        }}
                        navigation={true}
                        modules={[Autoplay, Pagination, Navigation, EffectCube]}
                        initialSlide={0}
                        className="mySwiper mx-auto 1200px:max-w-[1200px] px-20 1200px:px-0 pt-4"
                    >
                        {
                            descriptionData.map((data) => (
                                <SwiperSlide key={data.id}>
                                    <img
                                        className="w-full object-cover 1200px:h-[420px] md:h-72 sm:h-60 my-4 mx-auto"
                                        src={data.imageURL}
                                        alt={`image` + data.id}
                                    />
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                </div>

                <div className="w-full 1200px:w-1/2 text-left">
                    <p className="1200px:text-lg text-sm mb-4">
                        Welcome to <span className="font-bold">ARRKS Inovtech Pvt Ltd</span>. empowering Industries with Industry 5.0 Solutions
                        and Cutting-edge Training Programs. Unlocking the potential of Industry 5.0, ARRKS
                        Inovtech Pvt Ltd is your trusted partner in revolutionizing manufacturing and process
                        industries across diverse sectors. From manufacturing and agriculture to healthcare and
                        supply chain management, we provide innovative solutions and expert services to drive
                        efficiency, productivity, and growth. Our comprehensive training programs cover essential
                        domains such as AI/ML, Data Science, Cyber Security, IIoT, ES, Industrial Automation,
                        Robotics, Drone and much more.
                    </p>

                    <p className="1200px:text-lg text-sm mb-4">
                        Explore our diverse range of services tailored to meet the evolving needs of manufacturing
                        and process industries. Whether you&#39;re looking to enhance efficiency, implement advanced
                        technologies, or drive innovation, ARRKS Inovtech is here to empower your journey towards
                        excellence.
                    </p>

                    <p className="1200px:text-lg text-sm">
                        Unlock the future of industry with ARRKS Inovtech - Where Innovation Meets Technology!
                    </p>
                </div>
            </div>
        </div>



    );
};

export default Description;