import React from 'react';
import image from '../assets/vision_1.png';

const Vision = () => {
    return (
        <div className="container mx-auto p-4 text-center">
            <h1 className="1200px:text-2xl text-lg font-bold mb-4 text-blue-500 uppercase">
                Vision
            </h1>

            <div className='flex flex-col 1200px:flex-row justify-between items-center gap-8 pb-4'>
                <div className="w-full 1200px:w-1/2 text-left">
                    <p className="1200px:text-lg text-sm mb-4">
                        To drive innovation and technology adoption across industries, empowering businesses with agility, efficiency and sustainability.
                    </p>

                    <p className="1200px:text-lg text-sm mb-4">
                        <span className="font-bold">ARRKS Inovtech</span> foster a collaborative environment for revolutionary ideas, delivering cutting-edge solutions. As a preferred partner for educational institutions, industrial associations, and professional bodies, <span className="font-bold">ARRKS Inovtech</span> enable Industry 5.0 success.
                    </p>

                    <p className="1200px:text-lg text-sm">
                        <span className="font-bold">ARRKS Inovtech</span> envision global SMEs thriving with advanced software and IoT solutions, igniting continuous growth and prosperity.
                    </p>
                </div>

                <div className="w-full 1200px:w-1/2 text-left">
                    <img className="w-full rounded-ss-full rounded-ee-full object-cover 1200px:h-[420px] md:h-72 sm:h-60 my-4 mx-auto"
                        src={image} alt='image'
                    />
                </div>
            </div>

            <h1 className="1200px:text-2xl text-lg font-bold mb-4 text-blue-500 uppercase">
                Mission
            </h1>

            <div className='py-4 md:px-36 px-4'>
                <ol className="relative border-s-2 border-gray-400 dark:border-gray-700">
                    <li className="mb-10 ms-6 md:px-16 px-4">
                        <span className="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -start-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                            <svg className="w-8 h-8 text-blue-800 dark:text-blue-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                            </svg>
                        </span>
                        <h3 className="mb-2 md:text-xl text-lg px-8 md:px-0 font-semibold text-gray-900 dark:text-white">Empowering Innovation and Research</h3>
                        <p className="1200px:text-lg text-sm text-left px-8 md:px-0 font-normal text-gray-900 dark:text-gray-400"><span className='font-medium'>ARRKS Inovtech</span> establish aglobal innovation and technology center, encouraging collaborationand cutting-edge research to drive business innovation.</p>
                    </li>

                    <li className="mb-10 ms-6 md:px-16 px-4">
                        <span className="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -start-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                            <svg className="w-8 h-8 text-blue-800 dark:text-blue-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                            </svg>
                        </span>
                        <h3 className="mb-2 md:text-xl text-lg px-8 md:px-0 font-semibold text-gray-900 dark:text-white">Enabling Industry 5.0 Solutions</h3>
                        <p className="1200px:text-lg text-sm text-left px-8 md:px-0 font-normal text-gray-900 dark:text-gray-400">Through exceptional makers space infrastructure, <span className='font-medium'>ARRKS Inovtech</span> assist educational institutions, industrial associations and professional bodies in fully embracing Industry 5.0 with resources and knowledge.</p>
                    </li>

                    <li className="mb-10 ms-6 md:px-16 px-4">
                        <span className="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -start-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                            <svg className="w-8 h-8 text-blue-800 dark:text-blue-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                            </svg>
                        </span>
                        <h3 className="mb-2 md:text-xl text-lg px-8 md:px-0 font-semibold text-gray-900 dark:text-white">Driving SMEs Growth</h3>
                        <p className="1200px:text-lg text-sm text-left px-8 md:px-0 font-normal text-gray-900 dark:text-gray-400">Recognizing the importance of SMEs, <span className='font-medium'>ARRKS Inovtech</span> provide tailored software, IoT products and Industry 5.0 solutions to enhance their productivity and competitiveness, ensuring sustainable growth and success.</p>
                    </li>

                    <li className="mb-10 ms-6 md:px-16 px-4">
                        <span className="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -start-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                            <svg className="w-8 h-8 text-blue-800 dark:text-blue-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                            </svg>
                        </span>
                        <h3 className="mb-2 md:text-xl text-lg px-8 md:px-0 font-semibold text-gray-900 dark:text-white">Delivering Industry Excellence</h3>
                        <p className="1200px:text-lg text-sm text-left px-8 md:px-0 font-normal text-gray-900 dark:text-gray-400">Our dedicated experts offer top-notch consulting services, guiding businesses towards operational optimization and fostering a culture of continuous improvement.</p>
                    </li>
                </ol>


            </div>

        </div>
    )
}

export default Vision