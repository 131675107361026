import React from 'react'

const Banner = ({title}) => {
  return (
    <div className='bg-[#25aae1] text-white font-bold text-lg py-4 mb-4 text-center 1200px:text-2xl uppercase'>
        {title}
    </div>
  )
}

export default Banner