import React from 'react';
import { servicesData, coursesData, partnerData, productsData } from '../constants.js';
import Description from './Description.js';
import Banner from './Banner.js';
import CardsContainer from './CardsContainer.js';
import ChooseUs from './ChooseUs.js';
import Footer from './Footer.js';
import Container from './Container.js';
import Vision from './Vision.js';

const Body = () => {
  return (
    <div>
      <div className='w-full'>
        <Container />
        <Description />
        <Banner title="Vision & Mission" />
        <Vision />
        <Banner title="Our Services" />
        <CardsContainer data={servicesData} type="service" delay="2000" />
        <Banner title="Our Courses" />
        <CardsContainer data={coursesData} type="course" delay="3000" />
        <Banner title="Our Products" />
        <CardsContainer data={productsData} type="product" delay="2500" />
        <Banner title="Why Choose us?" />
        <ChooseUs />
        <Banner title="Corporate Partners" />
        <CardsContainer data={partnerData} type="partner" delay="3500" />
        <Footer />
      </div>
    </div>
  )
}

export default Body;