import React from 'react';
import Card from './Card.js';
import { useNavigate } from 'react-router-dom';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation, EffectCube } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-cube';
import PartnerCard from './PartnerCard.js';
import TeamCard from './TeamCard.js';


const CardsContainer = ({ data, delay, type }) => {
  const navigate = useNavigate();

  const handleClick = (id) => {
    // if (type === 'service') {
    //   navigate(`/servicedetail/${id}`)
    // } else if (type === 'course') {
    //   navigate(`/coursedetail/${id}`)
    // } else if(type === 'product') {
    //   navigate(`/productdetail/${id}`)
    // } else {
    //   return;
    // }
    return;
  }

  return (
    <div className='flex flex-col 1200px:flex-row justify-between items-center gap-8 pb-4 mb-4'>
      <div className='w-full'>
        <Swiper
          spaceBetween={30}
          centeredSlides={false}
          autoplay={{
            delay: delay,
            disableOnInteraction: true,
            pauseOnMouseEnter: true
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation, EffectCube]}
          initialSlide={0}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            1200: {
              slidesPerView: 3,
            },
          }}
          className="mySwiper mx-auto 1200px:max-w-[1200px] px-10 1200px:px-0 pt-4"
        >
          {
            data.map((card) => (
              <SwiperSlide className='h-auto' key={card.id} onClick={() => handleClick(card.id)}>
                {
                  type === 'partner' ? (
                    <PartnerCard key={card.id} name={card.name} imageURL={card.imageURL} description={card.description} />
                  ) : type === 'team' ? (
                    <TeamCard key={card.id} {...card} />
                  ) :
                  (
                    <Card key={card.id} title={card.title} imageURL={card.imageURL} description={card.description} />
                  )
                }
              </SwiperSlide>
            ))
          }
        </Swiper>
      </div>
    </div>
  )
}

export default CardsContainer;