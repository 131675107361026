import React from 'react';
import image1 from '../assets/choose_1.png';
import image2 from '../assets/choose_2.png';
import image3 from '../assets/choose_3.png';

const ChooseUs = () => {
    return (
        <div className='mb-4'>
            <h1 className="1200px:text-xl text-base text-center p-4 text-gray-900">
                We believe that ideas can change life that is why we encourage new ideas.
            </h1>

            <div className="w-full mx-auto p-6 text-center bg-white dark:bg-gray-800">
                <img className='mx-auto mb-2 w-16 h-16 lg:w-20 lg:h-20' src={image1} alt="image1" />
                <h5 className="mb-2 text-lg 1200px:text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">Expertise and Innovation</h5>
                <p className="mb-3 font-normal text-sm px-4 1200px:text-lg 1200px:px-32 text-gray-900 dark:text-gray-400">Our team consists of experienced professionals who stay updated with the latest trends and technologies. We combine our expertise with a passion for innovation to deliver state-of-the-art solutions that drive digital transformation.</p>
            </div>

            <div className="w-full mx-auto p-6 text-center bg-white dark:bg-gray-800">
                <img className='mx-auto mb-2 w-20 h-20 lg:w-24 lg:h-24' src={image2} alt="image2" />
                <h5 className="mb-2 text-lg 1200px:text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">Client - Centric Approach</h5>
                <p className="mb-3 font-normal text-sm px-4 1200px:text-lg 1200px:px-32 text-gray-900 dark:text-gray-400">We prioritize understanding your business goals and challenges. By closely collaborating with you, we ensure that our solutions align with your specific requirements and deliver tangible results.</p>
            </div>

            <div className="w-full mx-auto p-6 text-center bg-white dark:bg-gray-800">
                <img className='mx-auto mb-2 w-12 h-16 lg:w-16 lg:h-20' src={image3} alt="image3" />
                <h5 className="mb-2 text-lg 1200px:text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">Quality and Reliability</h5>
                <p className="mb-3 font-normal text-sm px-4 1200px:text-lg 1200px:px-32 text-gray-900 dark:text-gray-400">We are committed to delivering products and services of the highest quality. Our rigorous quality assurance processes and industry best practices ensure reliable and robust solutions.</p>
            </div>

        </div>
    )
}

export default ChooseUs;