import React from 'react';
import banner from '../assets/banner-2.png';

const Container = () => {
    return (
        <div className='flex flex-col md:flex-row bg-[#136b9d]'>
            <div className='w-full md:w-1/2 1200px:py-24 lg:py-4 items-center'>
                <section className="bg-center">
                    <div className="px-4 mx-auto max-w-screen-xl text-center py-4 lg:py-4">
                        <h1 className="mb-8 1200px:text-4xl lg:text-2xl text-xl 1200px:py-0 py-4 font-bold text-center tracking-normal leading-none text-white">Welcome to
                            ARRKS INOVTECH</h1>
                        <p className="mb-8 italic text-base font-semibold text-gray-200 1200px:text-xl px-4">"Smart Manufacturing, Smarter Workforce Unlock the potential of Industry 5.0 with ARRKS INOVTECH's training and industry-specific solutions."</p>
                    </div>
                </section>
            </div>
            <div className='w-full md:w-1/2'>
                <img className='w-full object-cover' src={banner} alt='banner' />
            </div>
        </div>

    )
}

export default Container