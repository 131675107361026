import descriptionImg1 from "../src/assets/desc_1.png";
import descriptionImg2 from "../src/assets/desc_2.png";
import descriptionImg3 from "../src/assets/desc_3.png";
import descriptionImg4 from "../src/assets/desc_4.png";
import servicesImg1 from "../src/assets/services_1.jpg";
import servicesImg2 from "../src/assets/services_2.jpg";
import servicesImg3 from "../src/assets/services_3.png";
import servicesImg4 from "../src/assets/services_4.png";
import servicesImg5 from "../src/assets/services_5.jpg";
import servicesImg6 from "../src/assets/services_6.jpg";
import coursesImg1 from "../src/assets/course_1.jpg";
import coursesImg2 from "../src/assets/course_2.jpg";
import coursesImg3 from "../src/assets/course_3.png";
import coursesImg4 from "../src/assets/course_4.jpg";
import coursesImg5 from "../src/assets/course_5.png";
import coursesImg6 from "../src/assets/course_6.jpg";
import partnerImg1 from "../src/assets/partner_1.png";
import partnerImg2 from "../src/assets/partner_2.jpg";
import partnerImg3 from "../src/assets/partner_3.png";
import partnerImg4 from "../src/assets/partner_4.png";
import partnerImg5 from "../src/assets/partner_5.jpg";
import teamImg1 from "../src/assets/shivanna.jpg";
import teamImg2 from "../src/assets/shivshankar.png";
import teamImg3 from "../src/assets/vijayanand.png";
import teamImg4 from "../src/assets/shridhar.png";
import teamImg5 from "../src/assets/yogendra.png";
import teamImg6 from "../src/assets/verma.png";
import teamImg7 from "../src/assets/kiran.png";
import teamImg8 from "../src/assets/venkatesh.png";
import teamImg9 from "../src/assets/shreekanth.png";
import teamImg10 from "../src/assets/somashekar.png";
import teamImg11 from "../src/assets/nagaraj.png";
import teamImg12 from "../src/assets/prahlad.png";
import teamImg13 from "../src/assets/vishwanath.png";
import teamImg14 from "../src/assets/satheeh.png";
import newsImg1 from "../src/assets/news_1.jpg";
import newsImg2 from "../src/assets/news_2.jpg";
import galImg1 from "../src/assets/gallery_1.jpg";
import galImg2 from "../src/assets/gallery_2.jpg";
import galImg3 from "../src/assets/gallery_3.jpg";
import galImg4 from "../src/assets/gallery_4.jpg";
import galImg5 from "../src/assets/gallery_5.jpg";
import galImg6 from "../src/assets/gallery_6.jpg";
import galImg7 from "../src/assets/gallery_7.jpg";
import galImg8 from "../src/assets/gallery_8.jpg";
import galImg9 from "../src/assets/gallery_9.jpg";
import galImg12 from "../src/assets/gallery_12.jpeg";
import galImg14 from "../src/assets/gallery_14.jpeg";
import galImg15 from "../src/assets/gallery_15.jpeg";
import prodImg1 from "../src/assets/product_1.png";
import prodImg2 from "../src/assets/product_2.jpeg";
import prodImg3 from "../src/assets/product_3.jpeg";
import prodImg4 from "../src/assets/product_4.png";
import prodImg5 from "../src/assets/product_5.png";
import prodImg6 from "../src/assets/product_6.jpeg";

export const descriptionData = [
    {
        id: 1,
        imageURL: descriptionImg1
    },
    {
        id: 2,
        imageURL: descriptionImg2
    },
    {
        id: 3,
        imageURL: descriptionImg3
    },
    {
        id: 4,
        imageURL: descriptionImg4
    }
];

export const servicesData = [
    {
        id: 1,
        title: 'Global Research & Innovation Center',
        description: 'Embrace the Fourth Industrial Revolution with state-of-the-art labs and transformative Industry 4.0 services.',
        imageURL: servicesImg1
    },
    {
        id: 2,
        title: 'Career Excellence Program',
        description: 'The programs are defined to equip students, faculties, and industry professionals with the skills and knowledge needed to excel in the rapidly evolving landscape of Industry 4.0.',
        imageURL: servicesImg2
    },
    {
        id: 3,
        title: 'Industry Excellence Consulting Services',
        description: 'Embrace the Fourth Industrial Revolution with state-of-the-art labs and transformative Industry 4.0 services.',
        imageURL: servicesImg3
    },
    {
        id: 4,
        title: 'Industry 4.0 Solutions for MSMEs',
        description: 'Transform your business with advanced automation, real-time data analytics, AI-driven insights, and seamless integration.',
        imageURL: servicesImg4
    },
    {
        id: 5,
        title: 'ISO Management Certification Consultancy',
        description: 'Supporting businesses excel through streamlined management systems and internationally recognized ISO certifications',
        imageURL: servicesImg5
    },
    {
        id: 6,
        title: 'IPR Services',
        description: 'Our comprehensive IPR services cater to Educational Institutions, Professional Bodies, Industries, and Research Scholars.',
        imageURL: servicesImg6
    }
];

export const productsData = [
    {
        id: 1,
        title: 'Car Parking Controller',
        description: 'Manages the entry, exit, and allocation of parking spaces in a designated area.',
        imageURL: prodImg1
    },
    {
        id: 2,
        title: 'Smart Water Level Controller',
        description: 'Monitors and controls the level of water in tanks or reservoirs to prevent overflow or depletion.',
        imageURL: prodImg2
    },
    {
        id: 3,
        title: 'Convayer Controller',
        description: 'Oversees the operation and speed of conveyor belts for efficient material handling in industrial settings.',
        imageURL: prodImg3
    },
    {
        id: 4,
        title: 'Traffic Light Controller',
        description: 'Regulates the timing and sequence of traffic signals at intersections to ensure smooth traffic flow.',
        imageURL: prodImg4
    },
    {
        id: 5,
        title: 'Smart Energy Monitoring',
        description: 'Efficiently monitor energy consumption and optimize usage with smart energy monitoring solutions.',
        imageURL: prodImg5
    },
    {
        id: 6,
        title: 'PLC Trainer Kit',
        description: 'Provides hands-on training for programming and troubleshooting Programmable Logic Controllers (PLCs) used in automation and control systems.',
        imageURL: prodImg6
    },
];

export const coursesData = [
    {
        id: 1,
        title: 'Industrial Automation (IA)',
        description: 'Become accomplished at optimizing complex industrial processes, learn to integrate cutting- edge equipment, and evolve traditional manufacturing into a smart, streamlined ecosystem.',
        imageURL: coursesImg1
    },
    {
        id: 2,
        title: 'Robotics',
        description: 'Delve into the dynamic world of robotics. Grasp the mechanics, control theories, and intelligence algorithms that bring machines to life.',
        imageURL: coursesImg2
    },
    {
        id: 3,
        title: 'Internet of Things (IoT)',
        description: 'Connect and automate. Discover how devices communicate, gather data, and make automated decisions in real-time.',
        imageURL: coursesImg3
    },
    {
        id: 4,
        title: 'Embedded System (ES)',
        description: 'Dive into the intricate world of microcontrollers, system design, and real-time operating systems to create integrated, dedicated systems for specific tasks.',
        imageURL: coursesImg4
    },
    {
        id: 5,
        title: 'Artificial Intelligence (AI)',
        description: 'Uncover the potential of AI. Understand the processes, algorithms, and neural networks that power smart applications.',
        imageURL: coursesImg5
    },
    {
        id: 6,
        title: 'Machine Learning (ML)',
        description: 'Analyze, predict, and learn. Understand the algorithms and statistical models that machines use to improve their performance on a specific task.',
        imageURL: coursesImg6
    },
];

export const partnerData = [
    {
        id: 1,
        name: "E-Square Embedded Solutions",
        imageURL: partnerImg1,
        description: "Manufacture and Design of need based innovative products in the field of Embedded Systems."
    },
    {
        id: 2,
        name: "Refillbot",
        imageURL: partnerImg2,
        description: "Revolutionary IoT platform that can retro fit into any device or an industrial application with a simple plug-n-play hardware and software to make it Smart."
    },
    {
        id: 3,
        name: "iRobo Automation Private Limited",
        imageURL: partnerImg3,
        description: "Revolutionary IoT platform"
    },
    {
        id: 4,
        name: "PRAGYAN AI",
        imageURL: partnerImg4,
        description: "The company pledge to make student ready for industry jobs and transform professional into advance technology and empower them to apply the learning to solve company or society problems."
    },
    {
        id: 5,
        name: "MeghaAI",
        imageURL: partnerImg5,
        description: "Experience the power of MeghaAI – where AI-driven transformation meets sustainable innovation, revolutionizing the way we manufacture for a better tomorrow."
    },
];

export const teamData = [
    {
        id: 1,
        name: "Dr. Shivanna D.M.",
        imageURL: teamImg1,
        designation: "Co-founder and CEO",
        qualification: "M.Tech, Ph.D",
        experience: "Dr. Shivanna D M has R&D, Industry, Academia and an Entrepreneur with 20+ years of experience and travelled Singapore and Malaysia. Worked in many companies for over 11 years.",
        industry: "",
        academia: "DSCE, CMRIT, RU",
        expertise: "CIM, Robotics, PLM, Industry 4.0, SiC HE, CNC, IPR, CAD/CAM, Project management"
    },
    {
        id: 2,
        name: "H S Shiva Shankar",
        imageURL: teamImg2,
        designation: "Technical Head-Industrial Automation",
        qualification: "BE MIE MIIM",
        experience: "A seasoned engineer with extensive experience in Instrumentation and Industrial Automation. With over 38 years of service AGM(Retired) from VISL, Bhadravathi, Former core research group engineer & branch manager Prolific systems and technologies pvt ltd, Bangalore. Has widely travelled in England, Austria, Germany and Holland for hands on experience in Instrumentation.",
        industry: "",
        academia: "",
        expertise: ""
    },
    {
        id: 3,
        name: "Dr. Vijayananda Kaup",
        imageURL: teamImg3,
        designation: "Technical Advisor",
        qualification: "ME (IISC), PhD(IISC), Bangalore",
        experience: "30+ years",
        industry: "Microsoft Corporation-USA, QuEST Global-worked on several projects for Gamma Technologies - USA, General Electrics - Canada, Toshiba Corporation - Japan, Rolls Royce - UK, John Crane - Singapore.",
        academia: "KREC, DSCE, CMRIT",
        expertise: "Aerospace, Robotics, Kinematics, Green Composites, Mechanics of Materials, Machine Design, and New Product Development"
    },
    {
        id: 4,
        name: "Dr. Shridhar Kurse",
        imageURL: teamImg4,
        designation: "Technical Consultant",
        qualification: "ME(BITS Pilani), Ph.D",
        experience: "30+ years",
        industry: "",
        academia: "Mumbai University, BITS Pilani, University of Tennessee, Knoxville, USA, Syracuse University, NY, USA, Dayananda Sagar College of Engineering, New Horizon College of Engineering, Bengaluru.",
        expertise: "PLM, Industry 5.0, Robotics, Electric vehicles, Mechatronics. Machine Learning, Programming, Simulation using Matlab, CAD/CAM software’s"
    },
    {
        id: 5,
        name: "Dr. Yogendra",
        imageURL: teamImg5,
        designation: "Technical Advisor",
        qualification: "DRDO Scientist (retired) at Gas Turbine Research Establishment, Bangalore",
        experience: "30+ years",
        industry: "ADE (DRDO), Served at the Foundry & Forge Division of M/s Hindustan Aeronautics Ltd., Naval Science & Technological Laboratory (DRDO), Visakhapatnam.",
        academia: "",
        expertise: "Non Ferrous Casting - Foundry"
    },
    {
        id: 6,
        name: "Prof.(Dr.) K B R Verma",
        imageURL: teamImg6,
        designation: "Technical Advisor",
        qualification: "PDC (Postdoctoral) from Strathclyde University, Glasgow, UK. PhD from Madras University, Chennai",
        experience: "40+ years",
        industry: "",
        academia: "Former Chairman, Materials Research Centre IISc-Bangalore, Former Vice-Chancellor, Sri Sathya Sai Institute of Higher Learning, Prasanthi Nilayam, Puttaparthi. Director of Research in PSG Institutions (including PSG Institute of Medical Sciences)",
        expertise: "Research & Development"
    },
    {
        id: 7,
        name: "Dr. M B Kiran",
        imageURL: teamImg7,
        designation: "Technical Consultant, Research and Product Management",
        qualification: "ME(PSGC), Ph.D. (IITM)",
        experience: "Dr. M.B. Kiran has industry, teaching, and research experience of 30 years. Worked in many multinational companies (viz. Bharat Fritz Werner’s Limited, Logica, Hewlett Packard-USA, BlueStar InfoTech Ltd.) for over 18 years.",
        industry: "",
        academia: "PDPU, DSCE",
        expertise: "Robotics, PLM, Industry 4.0, Product management & Project management (PMP)"
    },
    {
        id: 8,
        name: "Dr. G S Venkatesh",
        imageURL: teamImg8,
        designation: "Technical Advisor",
        qualification: "M.Tech(NITK), PhD(IISC)",
        experience: "30+ years",
        industry: "",
        academia: "UG & PG Coordinator, VTU, Center for Post Graduate Studies Muddenhalli",
        expertise: "Nanomaterials, Green Composites, Virtual Reality and Haptics"
    },
    {
        id: 9,
        name: "Dr. Shreekanth M Prabhu",
        imageURL: teamImg9,
        designation: "Technical Advisor",
        qualification: "M.Tech (IITB), PhD",
        experience: "30+ years",
        industry: "TCS, IBM and Hewlett Packard",
        academia: "PESU, CMRIT",
        expertise: "Computer Science Information Systems-Social Network, Information Framework, Data science, AI/ML. After that he worked with IT Majors such as TCS, IBM and Hewlett Packard for 25 years, where he held leadership positions in technology domains such as File Systems and Databases, Operating Systems and Utility Computing. He worked on international assignments in UK, USA and Norway during his stint with industry."
    },
    {
        id: 10,
        name: "Dr. Somashekhar Hiremath",
        imageURL: teamImg10,
        designation: "Technical Advisor",
        qualification: "ME, PhD (IIT Madras)",
        experience: "20+ years",
        industry: "Former Director for Tamil Nadu Small Industries Corporation Limited",
        academia: "Former Professor at Ecole Centrale de Nantes. France and Warsaw University of Technology, Poland. Professor at IIT Madras.",
        expertise: "Mechatronic System Design, Robotics, Micromachining, Additive Manufacturing, Smart Manufacturing."
    },
    {
        id: 11,
        name: "Nagaraj Hediyal",
        imageURL: teamImg11,
        designation: "Technical Advisor",
        qualification: "M.Tech, (Phd)",
        experience: "33 years",
        industry: "",
        academia: "",
        expertise: "Power electronics, renewable energy, embedded and VLSI systems, cryptography and network security, and protected communications."
    },
    {
        id: 12,
        name: "Dr. Prahlad N Tengli",
        imageURL: teamImg12,
        designation: "Technical Consultant",
        qualification: "ME, PhD",
        experience: "",
        industry: "Ex Director-Aeronautic, Scientist 'G'; O/o DG-Aero, DRDO Bangalore).",
        academia: "NMIT, Bangalore",
        expertise: "Dr Prahalad N Tengli worked as Director - Aeronautical Systems, General Manager and Project Director Rocket Motor Development,Testing& Production,System Manager (BrahMos and Nag), also, experienced in Automation, CNC, CAD, CAM, Product Development."
    },
    {
        id: 13,
        name: "Vishwanath Sangoji",
        imageURL: teamImg13,
        designation: "Technical Consultant",
        qualification: "BE, MBA",
        experience: "25+ years",
        industry: "Samsung, Harman, NEC",
        academia: "",
        expertise: "RPA, Smart gadgets, Embedded system, AI/ML, Consulting for end to end solutions for transit industry."
    },
    {
        id: 14,
        name: "Satheesh K R",
        imageURL: teamImg14,
        designation: "Technical Advisor",
        qualification: "BE, M.Tech",
        experience: "A serial entrepreneur and a ECE with 20+ years of experience in Industry, & research. Founder of Esquare Embedded systems.",
        industry: "",
        academia: "",
        expertise: "Embedded system, Power Electronics, IoT."
    }
];

export const newsData = [
    {
        id: 1,
        title: 'Small ideas lead to game changing innovations',
        imageURL: newsImg1,
        date: 'March 19, 2024',
        courtsey: 'The Hindu',
        description1: 'Many of the game changing innovations originated from small original ideas, Vice-Chancellor of GM University S.R. Shankapal has said.',
        description2: 'He was speaking in Davangere on Monday during the inauguration of a programme, "Innovation and Business Incubation and Entrepreneurship", organised as part of the ongoing Innovation Week at the university.',
        linkURL: 'https://www.thehindu.com/news/national/karnataka/small-ideas-lead-to-game-changing-innovations/article67968433.ece'
    },
    {
        id: 2,
        title: 'Students come up with innovative ideas to win cash prizes at GM University event',
        imageURL: newsImg2,
        date: 'March 25, 2024',
        courtsey: 'The Hindu',
        description1: 'As many as 1,250 students from engineering, management and pharmacy streams participated in various activities organised as part of the Innovation Week at GM University in Davangere and subsequently, came up with innovative ideas to bag cash prizes in various categories.',
        description2: 'The weeklong activities and various sessions concluded on Saturday with best innovative ideas being rewarded.',
        linkURL: 'https://www.thehindu.com/news/national/karnataka/students-come-up-with-innovative-ideas-to-win-cash-prizes-at-gm-university-event/article67991659.ece'
    }
];

export const imageGalleryData = [
    { id: 1, imageURL: galImg1, captionText: 'Image' },
    { id: 2, imageURL: galImg2, captionText: 'Image' },
    { id: 3, imageURL: galImg3, captionText: 'Image' },
    { id: 4, imageURL: galImg4, captionText: 'Image' },
    { id: 5, imageURL: galImg5, captionText: 'Image' },
    { id: 6, imageURL: galImg6, captionText: 'Image' },
    { id: 7, imageURL: galImg7, captionText: 'Image' },
    { id: 8, imageURL: galImg8, captionText: 'Image' },
    { id: 9, imageURL: galImg9, captionText: 'Image' },
    { id: 12, imageURL: galImg12, captionText: 'Image' },
    { id: 14, imageURL: galImg14, captionText: 'Image' },
    { id: 15, imageURL: galImg15, captionText: 'Image' },
];