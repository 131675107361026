import React from 'react'
import Banner from './Banner.js'
import { servicesData } from '../constants.js';
import Card from './Card.js';
import { useNavigate } from 'react-router-dom';

const Services = () => {
  const navigate = useNavigate();

  const handleClick = (id) => {
    // navigate(`/servicedetail/${id}`);
    return;
  }

  return (
    <div className='w-full py-2'>
      <Banner title="Our Services" />

      <div className='flex flex-wrap justify-center gap-8 py-4 md:flex-row sm:flex-col px-4'>
        {servicesData.map((card) => (
          <div key={card.id} onClick={() => handleClick(card.id)}>
            <Card title={card.title} imageURL={card.imageURL} description={card.description} />
          </div>
        ))}
      </div>

    </div>

  )
}

export default Services