import React from 'react';
import logo from '../assets/logo.png';
import { NavLink } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="bg-[#25aae1] dark:bg-gray-900">
            <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
                <div className="1200px:flex 1200px:justify-between">
                    <div className="mb-6 1200px:mb-0 w-3/10 flex-shrink-0">
                        <a href="/" className="flex items-center">
                            <img src={logo} className="1200px:h-16 h-8 me-3 bg-white" alt="Logo" />
                        </a>
                        <p className="mt-6 text-gray-200 dark:text-gray-400 font-medium">
                            In Driving The Technological Revolution, <br /> Delivering Advanced Services And Solutions <br /> Across Diverse Business Sectors.
                        </p>
                    </div>
                    <div className="grid grid-cols-1 sm:text-center gap-8 sm:gap-6 sm:grid-cols-3 w-7/10 sm:w-auto">
                        <div>
                            <h2 className="mb-6 text-sm font-bold text-white uppercase dark:text-white">Contact Us</h2>
                            <ul className="text-gray-200 dark:text-gray-400 font-medium">
                                <li className="mb-4">
                                    <a href="#" className="hover:underline">contact@arrksinovtech.com</a>
                                </li>
                                <li className="mb-4">
                                    +91-9606721325 <br /> +91-9606704225
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2 className="mb-6 text-sm font-bold text-white uppercase dark:text-white">Links</h2>
                            <ul className="text-gray-200 dark:text-gray-400 font-medium">
                                <li className="mb-4"><NavLink to="/" className="hover:underline">Home</NavLink></li>
                                <li className='mb-4'><NavLink to="/about" className="hover:underline">About</NavLink></li>
                                <li className='mb-4'><NavLink to="/services" className="hover:underline">Services</NavLink></li>
                                <li className='mb-4'><NavLink to="/courses" className="hover:underline">Courses</NavLink></li>
                                <li className='mb-4'><NavLink to="/products" className="hover:underline">Products</NavLink></li>
                                <li className='mb-4'><NavLink to="/news" className="hover:underline">News and Events</NavLink></li>
                                <li className='mb-4'><NavLink to="/contact" className="hover:underline">Contact</NavLink></li>
                            </ul>
                        </div>
                        <div>
                            <h2 className="mb-6 text-sm font-bold text-white uppercase dark:text-white">Follow us</h2>
                            <ul className="text-gray-200 dark:text-gray-300 font-medium">
                                <li className="mb-4"><a href="/" className="hover:underline">Facebook</a></li>
                                <li className="mb-4"><a href="/" className="hover:underline">YouTube</a></li>
                                <li className="mb-4"><a href="/" className="hover:underline">Instagram</a></li>
                                <li className="mb-4"><a href="/" className="hover:underline">LinkedIn</a></li>
                                <li className="mb-4"><a href="/" className="hover:underline">Twitter</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <hr className="my-6 border-white sm:mx-auto dark:border-gray-700 lg:my-8" />
                <div className="sm:flex sm:items-center sm:justify-between">
                    <span className="text-sm text-white sm:text-center dark:text-gray-400">© 2024 <a href="/" className="hover:underline">ARRKS INOVTECH</a>. All Rights Reserved.
                    </span>
                    <div className="flex mt-4 sm:justify-center sm:mt-0">
                        <a href="#" className="text-white hover:text-gray-900 dark:hover:text-white">
                            <svg className="w-7 h-7" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 8 19">
                                <path fillRule="evenodd" d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z" clipRule="evenodd" />
                            </svg>
                            <span className="sr-only">Facebook</span>
                        </a>
                        <a href="#" className="text-white hover:text-gray-900 dark:hover:text-white ms-5">
                            <svg className="w-7 h-7" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                <path fillRule="evenodd" d="M21.7 8.037a4.26 4.26 0 0 0-.789-1.964 2.84 2.84 0 0 0-1.984-.839c-2.767-.2-6.926-.2-6.926-.2s-4.157 0-6.928.2a2.836 2.836 0 0 0-1.983.839 4.225 4.225 0 0 0-.79 1.965 30.146 30.146 0 0 0-.2 3.206v1.5a30.12 30.12 0 0 0 .2 3.206c.094.712.364 1.39.784 1.972.604.536 1.38.837 2.187.848 1.583.151 6.731.2 6.731.2s4.161 0 6.928-.2a2.844 2.844 0 0 0 1.985-.84 4.27 4.27 0 0 0 .787-1.965 30.12 30.12 0 0 0 .2-3.206v-1.516a30.672 30.672 0 0 0-.202-3.206Zm-11.692 6.554v-5.62l5.4 2.819-5.4 2.801Z" clipRule="evenodd" />
                            </svg>
                            <span className="sr-only">YouTube</span>
                        </a>
                        <a href="#" className="text-white hover:text-gray-900 dark:hover:text-white ms-5">
                            <svg className='w-7 h-7 text-white' viewBox="0 0 24 24" role="img" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule='evenodd' fill='currentColor' d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z" />
                            </svg>
                            <span className="sr-only">Instagram</span>
                        </a>
                        <a href="#" className="text-white hover:text-gray-900 dark:hover:text-white ms-5">
                            <svg className="w-7 h-7" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                <path fillRule="evenodd" d="M12.51 8.796v1.697a3.738 3.738 0 0 1 3.288-1.684c3.455 0 4.202 2.16 4.202 4.97V19.5h-3.2v-5.072c0-1.21-.244-2.766-2.128-2.766-1.827 0-2.139 1.317-2.139 2.676V19.5h-3.19V8.796h3.168ZM7.2 6.106a1.61 1.61 0 0 1-.988 1.483 1.595 1.595 0 0 1-1.743-.348A1.607 1.607 0 0 1 5.6 4.5a1.601 1.601 0 0 1 1.6 1.606Z" clipRule="evenodd" />
                                <path d="M7.2 8.809H4V19.5h3.2V8.809Z" />
                            </svg>
                            <span className="sr-only">LinkedIn</span>
                        </a>
                        <a href="#" className="text-white hover:text-gray-900 dark:hover:text-white ms-5">
                            <svg className="w-7 h-7" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                <path fillRule="evenodd" d="M22 5.892a8.178 8.178 0 0 1-2.355.635 4.074 4.074 0 0 0 1.8-2.235 8.343 8.343 0 0 1-2.605.981A4.13 4.13 0 0 0 15.85 4a4.068 4.068 0 0 0-4.1 4.038c0 .31.035.618.105.919A11.705 11.705 0 0 1 3.4 4.734a4.006 4.006 0 0 0 1.268 5.392 4.165 4.165 0 0 1-1.859-.5v.05A4.057 4.057 0 0 0 6.1 13.635a4.192 4.192 0 0 1-1.856.07 4.108 4.108 0 0 0 3.831 2.807A8.36 8.36 0 0 1 2 18.184 11.732 11.732 0 0 0 8.291 20 11.502 11.502 0 0 0 19.964 8.5c0-.177 0-.349-.012-.523A8.143 8.143 0 0 0 22 5.892Z" clipRule="evenodd" />
                            </svg>
                            <span className="sr-only">Twitter</span>
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;