import React from 'react'

const Card = ({ imageURL, title, description }) => {
    return (
        <div className="max-w-sm bg-white cursor-pointer border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 h-96 sm:h-auto">
            <img className="w-full object-cover rounded-t-lg h-48" src={imageURL} alt="" />
            <div className="p-5 h-48">
                <h5 className="mb-2 1200px:text-xl text-lg font-bold tracking-tight text-gray-900 dark:text-white">{title}</h5>
                <p className="mb-3 font-normal 1200px:text-base text-sm text-gray-900 dark:text-gray-400">{description}</p>
            </div>
        </div>
    )
}

export default Card