import React from 'react'

const PartnerCard = ({imageURL, name, description}) => {
    return (
        <div className="max-w-sm bg-white cursor-pointer border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 flex flex-col h-full py-4">
            <img className="h-[15rem] w-60 mx-auto object-contain" src={imageURL} alt="" />
            <div className="p-5 flex-1">
                <h5 className="mb-2 1200px:text-xl text-lg font-bold tracking-tight text-gray-900 dark:text-white">{name}</h5>
                <p className="mb-3 font-normal 1200px:text-base text-sm text-gray-900 dark:text-gray-400">{description}</p>
            </div>
        </div>
    )
}

export default PartnerCard