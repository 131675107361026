import React, { useEffect, useState } from 'react';


const NewsCard = ({ title, imageURL, courtsey, date, description1, description2, linkURL }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="1200px:max-w-lg max-w-md bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 h-[45rem] sm:h-auto">
            <img className="w-full object-cover rounded-t-lg h-[18rem]" src={imageURL} alt="" />
            <div className="p-5 h-[18rem]">
                <h5 className="mb-2 1200px:text-xl text-lg font-bold tracking-tight text-gray-900 dark:text-white">{title}</h5>
                <p className="mb-3 truncate-words-sm md:truncate-words-md font-normal 1200px:text-base text-sm text-gray-700 dark:text-gray-400">{description1}</p>
                {
                    windowWidth >= 1200 && (
                        <p className="mb-3 md:truncate-words-md font-normal 1200px:text-base text-sm text-gray-700 dark:text-gray-400">{description2}</p>
                    )
                }
                <div className='flex flex-col md:flex-row justify-between gap-4 py-3 h-16'>
                    <p className='font-normal 1200px:text-base text-sm text-gray-700 dark:text-gray-400'><span className='font-semibold'>Courtesy: </span>{courtsey}</p>
                    <p className='font-normal 1200px:text-base text-sm text-gray-700 dark:text-gray-400'><span className='font-semibold'>Date: </span>{date}</p>
                </div>
            </div>
            <div className='p-4 h-16 text-center 1200px:my-4 my-0'>
                <a href={linkURL} target='_blank' className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    Read More
                </a>
            </div>

        </div>
    )
}

export default NewsCard