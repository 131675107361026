import React from 'react'
import { coursesData } from '../constants.js'
import Card from './Card.js'
import { useNavigate } from 'react-router-dom';
import Banner from './Banner.js';

const Courses = () => {
  const navigate = useNavigate();

  const handleClick = (id) => {
    // navigate(`/coursedetail/${id}`);
    return;
  }

  return (
    <div className='w-full py-2'>
      <Banner title="Our Courses" />

      <div className='flex flex-wrap justify-center gap-8 py-4 md:flex-row sm:flex-col px-4'>
        {coursesData.map((card) => (
          <div key={card.id} onClick={() => handleClick(card.id)}>
            <Card title={card.title} imageURL={card.imageURL} description={card.description} />
          </div>
        ))}
      </div>

    </div>
  )
}

export default Courses