import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { Outlet, RouterProvider, createBrowserRouter, useLocation } from "react-router-dom";
import Header from "./components/Header.js";
import Body from "./components/Body.js";
import Footer from "./components/Footer.js";
import About from "./components/About.js";
import Courses from "./components/Courses.js";
import Services from "./components/Services.js";
import Products from "./components/Products.js";
import News from "./components/News.js";
import Contact from "./components/Contact.js";
import ServiceDetails from "./components/ServiceDetails.js";
import NotFound from "./components/NotFound.js";
import CourseDetails from "./components/CourseDetails.js";
import ProductDetails from "./components/ProductDetails.js";

const AppLayout = () => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
      <div className="pt-16">
        <Header />
        <Outlet />
      </div>
    );
};

const appRouter = createBrowserRouter([
    {
        path: "/",
        element: <AppLayout />,
        children: [
            {
                path: "/",
                element: <Body />
            },
            {
                path: "/about",
                element: <About />
            },
            {
                path: "/courses",
                element: <Courses />
            },
            {
                path: "/services",
                element: <Services />
            },
            {
                path: "/products",
                element: <Products />
            },
            {
                path: "/news",
                element: <News />
            },
            {
                path: "/contact",
                element: <Contact />
            },
            {
                path: "servicedetail/:id",
                element: <ServiceDetails />
            },
            {
                path: "coursedetail/:id",
                element: <CourseDetails />
            },
            {
                path: "productdetail/:id",
                element: <ProductDetails />
            },
            {
                path: "*",
                element: <NotFound />
            }
        ]
    }
]);



const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<RouterProvider router={appRouter} />);